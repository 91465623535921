<template>
	<div class="animated fadeIn">
		<div
			v-if="[distributor].includes(this.$user.details().type)"
			class="row mb-2">
			<div class="col">
				<div class="alert alert-info w-100 pl-1">
					<div class="row no-gutters">
						<div
							class="col-auto d-flex justify-content-center my-auto"
							style="width: 40px;">
							<i class="fas fa-info mr-1" />
						</div>
						<div class="col">
							<span>{{ translate('coupons_description') }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<b-button
					v-if="admin.includes(this.$user.details().type)"
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<b-button
					v-if="$can('coupons', 'create') && admin.includes(this.$user.details().type)"
					class="float-right mr-2"
					variant="primary"
					@click="goToGenerateCoupons()">
					{{ translate('add') }}
				</b-button>
				<b-button
					v-if="$can('coupons', 'create') && [distributor].includes(this.$user.details().type)"
					:disabled="generatingCoupon"
					class="float-right"
					variant="primary"
					@click="couponRate()">
					<template v-if="!generatingCoupon">
						{{ translate('generate_coupon') }}
					</template>
					<template v-else>
						<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
					</template>
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			v-if="admin.includes(this.$user.details().type)"
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-sm">
						<search
							v-model="filters.username"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{ role: distributor }"
							class="mb-3"
							q="label"
							return-key="username" />
					</div>
				</div>
			</template>
		</data-filter>
		<b-row>
			<b-col class="col-12">
				<b-tabs v-model="tabIndex">
					<template v-for="(tabInfo, index) in tabs">
						<b-tab :key="index">
							<template slot="title">
								<router-link
									:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
									:to="{ name: tabInfo.name }"
									class="list-group-item btn text-left">
									{{ translate(tabInfo.translate_key) }}
								</router-link>
							</template>
							<template
								v-if="tabIndex === index"
								class="p-0">
								<router-view :key="routerViewKey" />
							</template>
						</b-tab>
					</template>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import Vue from 'vue';
import Swal from 'sweetalert2';
import DataFilter from '@/components/DataFilter';
import GenerateCouponForm from '@/components/GenerateCouponForm';
import { SEARCH_USERS } from '@/config/endpoint';
import FiltersParams from '@/mixins/FiltersParams';
import { admin, distributor } from '@/settings/Roles';
import { Grids, Coupons as CouponMessages } from '@/translations';
import Coupons from '@/util/Coupons';
import { ALLOW_PERMANENT_COUPONS_IN_COUNTRIES, COUPONS_MAX_PERCENTAGE, COUPONS_MIN_PERCENTAGE } from '@/settings/Coupons';

const couponImage = require('@/assets/images/common/coupon.png');

export default {
	name: 'Coupons',
	messages: [Grids, CouponMessages],
	components: { DataFilter },
	mixins: [FiltersParams],
	data() {
		return {
			coupon: new Coupons(),
			alert: new this.$Alert(),
			endpoint: SEARCH_USERS.endpoint,
			admin,
			distributor,
			tabIndex: 0,
			routerViewKey: '',
			tabs: [
				{
					name: 'CouponsActive',
					translate_key: 'active_tab',
				},
				{
					name: 'CouponsUsed',
					translate_key: 'used_tab',
				},
				{
					name: 'CouponsExpired',
					translate_key: 'expired_tab',
				},
			],
		};
	},
	computed: {
		generatingCoupon() {
			return this.coupon.data.loading;
		},
	},
	created() {
		this.showFilters = false;
		this.dontOpenFiltersFor.push('status');
		this.getFiltersfromUrl();
	},
	methods: {
		couponRate() {
			const style = getComputedStyle(document.body);
			const config = {
				config: {
					allowEscapeKey: false,
					allowOutsideClick: false,
					showLoaderOnConfirm: true,
					showCancelButton: true,
					cancelButtonColor: style.getPropertyValue('--gray'),
					confirmButtonColor: style.getPropertyValue('--primary'),
					cancelButtonText: this.translate('close'),
					confirmButtonText: this.translate('generate'),
					imageUrl: couponImage,
				},
			};
			const showPermanentField = ALLOW_PERMANENT_COUPONS_IN_COUNTRIES.includes(this.country);
			const min = COUPONS_MIN_PERCENTAGE;
			const max = COUPONS_MAX_PERCENTAGE;

			const ComponentClass = Vue.extend(GenerateCouponForm);
			const instance = new ComponentClass({
				propsData: {
					min,
					max,
					showPermanentField,
				},
			});
			instance.$mount(document.createElement('div'));
			const form = instance.$el.outerHTML;

			this.alert.confirmation(
				`<b>${this.translate('generate_coupon')}</b>`,
				`<p>${this.translate('generate_coupon_message', { min, max })}</p>
				${form}`,
				config,
				true,
				() => new Promise((resolve) => {
					const rate = document.getElementById('swal_rate').value;
					const isPermanent = document.getElementById('swal_is_permanent')?.checked ?? false;
					if (!rate) {
						Swal.showValidationMessage(this.translate('rate_required'));
					} else if (rate < min || rate > max) {
						Swal.showValidationMessage(this.translate('rate_between', { min, max }));
					}
					resolve({ rate: Number(rate), is_permanent: isPermanent });
				}),
			).then((response) => {
				this.generateCoupon(response.value);
			}).catch(() => {});
		},
		generateCoupon(options = {}) {
			this.coupon.generateCoupons({ ...options, quantity: 1 }).then((response) => {
				const couponCode = response.response.coupons[0];
				const style = getComputedStyle(document.body);
				const config = {
					config: {
						allowEscapeKey: false,
						allowOutsideClick: false,
						showLoaderOnConfirm: true,
						showCancelButton: true,
						cancelButtonColor: style.getPropertyValue('--gray'),
						confirmButtonColor: style.getPropertyValue('--primary'),
						cancelButtonText: this.translate('close'),
						confirmButtonText: `<i class="fa fa-clipboard mr-3"></i>${ this.translate('copy') }`,
						imageUrl: couponImage,
					},
				};
				this.alert.confirmation(
					`<b>${couponCode}</b>`,
					response.response.message,
					config,
					true,
				).then(() => {
					this.$clipboard(couponCode);
					this.alert.toast('success', this.translate('coupon_code_copied'));
				}).catch(() => {
				}).finally(() => {
					if (this.$route.name !== 'CouponsActive') {
						this.$router.push({ name: 'CouponsActive' }).catch(() => {});
					}
				});
				if (this.$route.name === 'CouponsActive') {
					this.routerViewKey = couponCode;
				}
			});
		},
		goToGenerateCoupons() {
			this.$router.push({ name: 'CouponsGenerate' }).catch(() => {});
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}

			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			this.$router.push({ name: this.$route.name, query: options }).catch(() => {});
		},
	},
};
</script>
